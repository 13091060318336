<script>
import InputField from '@/components/general/InputField'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ProspectInvite',
  components: {
    InputField
  },
  props: {
    color: { type: String, default: 'defaultLogin' },
    logo: { type: String, default: '/assets/images/dark-yellow-logo.png' },
    imageUrl: { type: String, default: '' },
    language: { type: String, default: '' },
    prospectInfo: {
      type: Object,
      default: () => ({})
    }
  },
  validations: {
    formData: {
      email: {
        required,
        email
      },
      password: {
        required,
        passwordOrEmailInvalid: {
          value: function () {
            return !this.pwOrEmailInvalid
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.setLanguage(language)
    },
    submit () {
      this.$v.formData.$touch()
      if (this.formData.email && this.formData.password) {
        this.login(this.formData)
      }
    },
    async prepareUserData (data) {
      try {
        const newUserData = await this.$store.dispatch('attemptSynchronizeUser', data)
        this.$store.commit('updateUser', newUserData.data)
        await this.$store.dispatch('attemptGetUserWorkspaces')
      } catch (error) {
        await this.$store.dispatch('attemptGetUserWorkspaces', true)
        this.$store.commit('updateUser', data)
      }
    },
    async login (formData) {
      try {
        this.attemptingLogin = true
        const response = await this.$store.dispatch('attemptPerformLogin', { email: formData.email, password: formData.password, workspace: this.$route.query.theme })
        localStorage.setItem('plooral-global', JSON.stringify({ token: response.data.token }))
        const userData = response.data.user
        let isSupport = false
        isSupport = !!(userData && userData.isSupport)
        if (response.data && !isSupport) {
          try {
            await this.prepareUserData(response.data)
            this.$store.getters.getUserWorkspaces.map(workspace => {
              if (this.$route.name.includes(workspace.type)) {
                if (workspace.type === 'individual' || (this.$route.params.companyDomain && this.$route.params.companyDomain.toLowerCase() === workspace.subdomain.replace(/\s+/g, '').toLowerCase())) {
                  this.$store.commit('setSelectedWorkspace', workspace)
                }
              }
            })

            this.changeLanguage(userData.language)
            this.attemptingLogin = false

            if (this.$route.query.theme) this.$store.commit('updateSetThemeWorkspace', this.$route.query.theme)

            const uri = decodeURIComponent(this.$route.query.redirect)
            if (uri !== 'undefined') {
              location.href = uri
            } else {
              this.$store.dispatch('attemptUpdateProspectUserToPosition', this.$route.params.hash).then(() => {
                this.$router.push({ name: 'individual.candidature.under.analysis', query: { help: 1 } })
              })
            }
          } catch (error) {
            this.attemptingLogin = false
          }
        } else if (isSupport) {
          this.debounceEvent(() => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('support.user.not.allowed')
            })
          }, 1000)
        }
        this.$store.commit('setLanguageIsLoaded', true)
      } catch (error) {
        this.pwOrEmailInvalid = true
        this.attemptingLogin = false
      }
    }
  },
  data () {
    return {
      pwOrEmailInvalid: false,
      isAttemptingLogin: false,
      formData: {
        email: this.prospectInfo.user.email,
        password: null
      }
    }
  }
}
</script>
<template>
  <div class="auth--container" :class="color">
    <div class="auth-container--wrapper">
      <div class="presentation--container">
        <div class="header--container">
          <img class="logo" :src="logo" />
        </div>
        <div :class="color === 'pwfglobal' || color === 'vivae'?'footer--container-wf-global':'footer--container'">
          <h1>{{$t('prospect.invite.title', {name: prospectInfo.user.name})}}</h1>
          <h5>{{$t('prospect.invite.subtitle2')}}</h5>
        </div>
      </div>
      <div class="auth--form">
        <div class="login--container">
      <v-card class="login-form--wrapper">
      <div style="text-align: center" v-if="!!prospectInfo.company.avatar">
        <v-avatar class="round" size="128">
          <v-img :src="prospectInfo.company.avatar" height="128px" width="128px"></v-img>
        </v-avatar>
      </div>
      <h4>{{ $t('login.form:title') + (prospectInfo.company.name ? ' | ' + prospectInfo.company.name : '') }}</h4>
        <input-field
          type="email"
          autocomplete="username"
          class="mt-10"
          outlined
          v-model="formData.email"
          :label="$t('registration.form:email')"
          :validation="$v.formData.email"
          :hide-validation="!$v.formData.email.$error && isMobile"
          @keydown.enter.prevent="submit"
          @click="pwOrEmailInvalid = false"
        />
        <input-field
          type="password"
          autocomplete="current-password"
          outlined
          v-model="formData.password"
          :label="$t('login.form:password')"
          :validation="$v.formData.password"
          :hide-validation="!$v.formData.password.$error && isMobile"
          @keydown.enter="submit()"
          @click="pwOrEmailInvalid = false"
        />
        <router-link class="login-form-subtitle" to='/recovery'>{{$t('login.form:recover')}}</router-link>
        <v-btn style="color: white; font-size: 16px;" class="mt-10 btn bold transform-unset" color="#1200D3" :loading="isAttemptingLogin" :disabled="$v.$invalid ||isAttemptingLogin" large block @click.stop="submit()">{{ $t(isAttemptingLogin ? 'global:wait' : 'login.form:submit') }}</v-btn>
      </v-card>
      </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.login--container {
  max-width: 100%;
  width: 515px;

  .login-form--wrapper {
    padding: 45px 64px;
    border-radius: 10px;
    text-align: left;

    .registration-form-subtitle {
      @extend .subtitle--1;
      @extend .neutral-medium;
    }
  }

  @media only screen and (max-width: 768px) {
    .login-form--wrapper {
      border-radius: 10px 10px 0 0;
      padding: 36px 24px 80px;
    }
  }
}
.auth--container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
  padding: 40px 40px 0;
  .auth-container--wrapper {
    width: 1250px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .presentation--container {
      justify-content: flex-start;
      width: 50%;
      .image--container {
        height: 330px;
        width: 330px;
        margin-top: 120px;
      }
      .footer--container {
        margin-top: 100px;
        color: $neutral-white;
        text-align: left;
        h1 {
          font-family: $lato;
          font-size: 72px;
          font-weight: 700;
        }
        h5 {
          font-family: $lato;
          font-size: 24px;
          font-weight: 400;
        }
      }
      .footer--container-wf-global{
          color: #212121;
        text-align: left;
        h1 {
          font-family: $lato;
          font-size: 72px;
          font-weight: 700;
        }
        h5 {
          font-family: $lato;
          font-size: 24px;
          font-weight: 400;
        }
        }

      .header--container {
        display: flex;
        width: 100%;
        .logo {
          height: 40px;
          width: 152px;
          margin-right: 36px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 0 0;
    .auth-container--wrapper {
      flex-direction: column;
      .auth--form {
        width: 100%;
        margin-left: unset;
        .registration--container {
          margin: 0 auto;
        }
      }
      .presentation--container {
        width: 100%;
        padding: 0 24px 24px;
        position: relative;
        .header--container {
          margin-bottom: 15px;
          justify-content: space-between;
        }
        .image--container {
          position: absolute;
          opacity: 0.5;
          width: 150px;
          height: 150px;
          bottom: -30px;
          right: 15px;
        }
        .footer--container {
          h1 {
            font-size: 24px;
          }
          h5 {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .presentation--container {
      .image--container {
        width: 300px;
        height: 300px;
      }
      .footer--container {
        h1 {
          font-size: 48px;
        }
        h5 {
          font-size: 18px;
        }
      }
    }
    .auth--form {
      margin-left: 30px
    }
  }
}
.primary-medium {
  background-color: $primary-medium;
}
.dark-blue {
  background-color: $primary-darkest;
}

.defaultLogin{
  background-color: #1971F3;
}
.empregosagro {
  background-color: #006C4C;
}
.vivae {
  background-color: #76E100;
}
.pwfglobal {
  background-color: #F1CF00;
}

.acats {
  background-color: #2D5AA4;
  .auth-container--wrapper {
    .presentation--container {
      .image--container {
        height: 150px;
      }
    }
  }
  @media only screen and (max-width: 768px) {

  }
}
@media only screen and (max-width: 768px) {
  .acats.auth--container .auth-container--wrapper .presentation--container .image--container {
    position: relative;
    opacity: 1;
    margin-top: 20px;
  }
  #app {
    padding-bottom: 0 !important;
  }
  div > #launcher {
    margin-bottom: 10px !important;
  }
}
</style>
