<script>
import ProspectInvite from './ProspectInvite.vue'
import ProspectInviteNew from './ProspectInviteNew.vue'
import Loading from '@/components/general/Loading'
import InviteErrorHandler from './InviteErrorHandler.vue'
export default {
  components: { ProspectInvite, ProspectInviteNew, Loading, InviteErrorHandler },
  data () {
    return {
      choose: 0,
      prospectResponse: {},
      isLoading: false
    }
  },
  computed: {
    getUser () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    resultHandler (response) {
      if (response.user) {
        this.choose = 1
        const formatedResponse = this.convertFormDataProspect(response.user)
        this.prospectResponse = { ...response, ...{ user: formatedResponse } }
      } else {
        this.choose = 2
        const formatedResponse = this.convertFormDataProspect(response.profile, response.company)
        this.prospectResponse = { ...response, ...{ user: formatedResponse } }
      }
    }
  },
  created () {
    this.isLoading = true
    const params = this.$route.params
    const info = { hash: params.hash }
    this.$store.dispatch('attemptCandidateProspect', info).then(result => {
      const prospectInfo = result.profile ? result.profile : result.user
      if (this.getUser && this.getUser.email && this.getUser.email.toLowerCase() === prospectInfo.email.toLowerCase()) {
        this.$store.dispatch('attemptUpdateProspectUserToPosition', this.$route.params.hash).then(() => {
          this.$router.push({ name: 'individual.candidature.under.analysis', query: { help: 1 } })
        })
      } else if (this.getUser && this.getUser.email && this.getUser.email.toLowerCase() !== prospectInfo.email.toLowerCase()) {
        this.$store.commit('setInviteDetails', { companyName: result.company.name, inviteEmail: prospectInfo.email, userEmail: this.getUser.email, showMessageInvite: true, title: 'global:ops', text: 'home.prospect.invite.my' })
        this.$router.push({ name: 'individual.home' })
      } else {
        this.resultHandler(result)
      }
      this.isLoading = false
    }).catch((err) => {
      this.isLoading = false
      this.choose = 3
      this.prospectResponse = err.response
    })
  }
}
</script>
<template >
  <div>
    <div v-if="choose > 1" class="firstAccess--header">
        <img :src="'/assets/images/primary-logo.svg'" />
    </div>
    <div v-if="!isLoading">
      <prospect-invite :prospectInfo="prospectResponse" v-if="choose === 1"/>
      <prospect-invite-new :prospectInfo="prospectResponse" v-if="choose  === 2"/>
      <invite-error-handler :response="prospectResponse" v-if="choose === 3"/>
    </div>
    <Loading v-else/>
  </div>
</template>
<style lang="scss">
.firstAccess--header {
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 0;
    left: 0;
    img {
      height: 24px;
    }
  }
</style>
