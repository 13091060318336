<script>
import ImageUploadProspect from '@/components/general/ImageUploadProspect'
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
export default {
  components: {
    ImageUploadProspect,
    UserAvatarInitials
  },
  name: 'ProspectPictureManage',
  props: {
    value: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object
    },
    themeColor: {
      type: String,
      default: ''
    },
    isNotMe: {
      type: Boolean,
      default: false
    },
    userProfile: {
      type: Object
    }
  },
  data () {
    return {
      isImageLoading: false,
      isImageSelected: false,
      selectedImage: null,
      mutableValue: ''
    }
  },
  watch: {
    selectedImage (value) {
      if (value !== null) this.isImageSelected = true
    },
    value () {
      this.mutableValue = this.value
    }
  },
  created () {
    this.mutableValue = this.value
  },
  methods: {
    imageLoad (image) {
      this.$emit('input', image)
    },
    chooseFiles () {
      document.getElementById('fileUpload').click()
    },
    onImageUpload (value) {
      this.$store.dispatch('attemptUpdateUserGlobalInfo', { avatar: value })
      this.$store.dispatch('attemptUpdateIndividualWorkspace', { avatar: value })
      this.mutableValue = value
    },
    onRemoveImage () {
      this.mutable = ''
    },
    onImageLoading (value) {
      this.isImageLoading = value
    }
  }
}
</script>
<template>
  <div class="profile-picture--container">
    <div class="profile-picture--header">
      <h3>{{ $t('profile.picture:title') }}</h3>
    </div>
    <div class="profile-picture--view">
      <v-avatar class="round" size="128" v-if="!!mutableValue">
        <v-img v-if="!!mutableValue && !(mutableValue.includes('/profile-avatar.jpg') || mutableValue.includes('/empty'))" :src="mutableValue" height="128px" width="128px"></v-img>
        <user-avatar-initials v-else :user="isNotMe ? userProfile : workspace" />
      </v-avatar>
      <v-avatar class="round" size="128" v-else></v-avatar>
      <v-progress-circular v-if="isImageLoading" indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="profile-picture--actions" v-if="!readOnly">
      <image-upload-prospect
        :vWidth="500"
        :vHeight="50"
        :width="500"
        :height="500"
        :aspectRatio="1"
        :themeColor="themeColor"
        @image="imageLoad"
        @upload-image="onImageUpload"
        @image-loading="onImageLoading"
        ref="imageUpload"
      />
    </div>
  </div>
</template>
<style lang="scss">
.profile-picture--container {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .profile-picture--header {
    background: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    text-align: left;
    h3 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #454545;
    }
  }
  .profile-picture--view {
    padding: 24px;
    background: #fff;
    text-align: left;
    position: relative;

    .v-progress-circular {
      position: absolute;
      left: 72px;
      top: 72px;
    }
  }
  .profile-picture--actions {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    .v-btn {
      margin-left: 16px;
    }
  }
}
</style>
