<script>
export default {
  data () {
    return {
      isReady: true,
      title: '',
      subtitle: ''
    }
  },
  props: {
    response: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    switch (this.response.data.message) {
      case 'Invitation accepted':
        this.subtitle = 'prospect.invite.error.subtitle1'
        break
      case 'Invitation declined':
        this.subtitle = 'prospect.invite.error.subtitle2'
        break
      case 'Invitation expired':
        this.subtitle = 'prospect.invite.error.subtitle3'
        break
      case 'Invitation canceled':
        this.subtitle = 'prospect.invite.error.subtitle4'
        break
      case 'Invitation not found':
        this.subtitle = 'prospect.invite.error.subtitle5'
        break
      default:
        this.subtitle = ''
        break
    }
  }
}
</script>
<template>
  <section class="firstAccess-selection--container">
    <v-container v-if="isReady" class="firstAccess--body">
        <div class="welcome-decline-content-wrapper">
            <h1>{{$t('prospect.invite.error.title')}}</h1>
            <h5> {{$t(subtitle)}}</h5>
        </div>
    </v-container>
  </section>
</template>
<style lang="scss">
  .firstAccess--body {
    .welcome-decline-content-wrapper{
      margin-top: 15vh;
      display:flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: left;
      h1{
        font-family: $lato;
        font-size: 50px;
      }
      h5 {
      margin-top: 2.25rem;
      color: #A0A0A0;
      font-family: $lato;
      font-size: 24px;
      font-weight: 400;
      }
    }
    max-width: 1100px;
    padding-top: 100px;
  }
</style>
